<template>
  <div>
    <user-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :groups="roles"
      @refetch-data="refetchData"
    />

    <user-list-edit
      :key="blankUserData.username"
      :is-add-new-user-sidebar-active.sync="isEditUserSidebarActive"
      :groups="roles"
      :blank-user-data="blankUserData"
      @refetch-data="refetchData"
    />

    <!-- Filters
    <users-list-filters
      :role-filter.sync="roleFilter"
      :plan-filter.sync="planFilter"
      :status-filter.sync="statusFilter"
    />
 	-->
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          />

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                variant="secondary"
                class="btn-icon p-50 mr-1"
                style="float: right"
                @click="
                  () => {
                    isSlectColumnActive = true;
                  }
                "
              >
                <feather-icon
                  icon="SettingsIcon"
                  size="20"
                />
              </b-button>
              <b-button
                variant="primary"
                @click="isAddNewUserSidebarActive = true"
              >
                <span class="text-nowrap">Создать</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchUsers"
        responsive
        :fields="[
          ...filteredFields,
          ...[
            {
              visible: true,
              label: '',
              key: 'setting',
              filter: false,
              tdClass: 'td-actions',
            },
          ],
        ]"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Результатов не найдено"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: User -->
        <template #cell(user)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.avatar"
                :text="avatarText(data.item.fullName)"
                :variant="`light-${resolveUserRoleVariant(data.item.role)}`"
                :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
              />
            </template>
            <b-link
              :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.fullName }}
            </b-link>
            <small class="text-muted">@{{ data.item.username }}</small>
          </b-media>
        </template>

        <!-- Column: Role -->
        <template #cell(role)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveUserRoleIcon(data.item.role)"
              size="18"
              class="mr-50"
              :class="`text-${resolveUserRoleVariant(data.item.role)}`"
            />
            <span class="align-text-top text-capitalize">{{ data.item.role }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>

        <template #cell(group_id)="data">
          {{ roles.find((role) => role.value == data.item.group_id).label }}
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              @click="
                blankUserData = data.item;
                isEditUserSidebarActive = true;
              "
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Редактировать</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted"
            >Показаны с {{ dataMeta.from }} до {{ dataMeta.to }} из
              {{ dataMeta.of }} результатов</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-sidebar
      id="add-new-user-sidebar"
      :visible.sync="isSlectColumnActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
      @hidden="isSlectColumnActive = false"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">
            Выбрать столбцы
          </h5>

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>

        <!-- BODY -->
        <div class="m-3">
          <b-form-group item="Form-checkbox-group stacked checkboxes">
            <b-form-checkbox-group
              v-model="selectedColumns"
              :options="optionsColumn"
              class="mb-3 p-1"
              style="padding: 5px"
              value-field="key"
              text-field="label"
              disabled-field="notEnabled"
              stacked
            />
          </b-form-group>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
  BFormCheckbox,
  BFormCheckboxGroup,
  BSidebar,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  ref, onUnmounted, computed, watch,onMounted
} from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import store from '@/store'
import UsersListFilters from './UsersListFilters.vue'
import useUsersList from './useUsersList'
import userStoreModule from '../userStoreModule'
import UserListAddNew from './UserListAddNew.vue'
import UserListEdit from './UserListEdit.vue'

export default {
  components: {
    UsersListFilters,
    UserListAddNew,
    UserListEdit,

    BCard,
    BRow,
    BCol,
    BFormGroup,
    BSidebar,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'
    const blankUserData = ref({})

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) { store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule) }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) { store.unregisterModule(USER_APP_STORE_MODULE_NAME) }
    })

    const isAddNewUserSidebarActive = ref(false)
    const isEditUserSidebarActive = ref(false)

    const {
      fetchUsers,
      fetchGroups,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      roles,
    } = useUsersList()
    const isSlectColumnActive = ref(false)
    const optionsColumn = tableColumns?.filter(t => t.visible)
    const selectedColumns = ref(JSON.parse(localStorage.getItem('selectedColsUser'))?.length > 0 ? optionsColumn.filter(t => !JSON.parse(localStorage.getItem('selectedColsUser')).includes(t.key)).map(t => t.key) : [...optionsColumn.map(t => t.key)])
    watch(selectedColumns, () => {
      let data = optionsColumn.filter(t => !selectedColumns.value.includes(t.key)).map(t => t.key)
      localStorage.setItem('selectedColsUser', JSON.stringify(data))
    })

    const filteredFields = computed(a => {
      return tableColumns.filter(field => selectedColumns.value?.includes(field.key))
    })

    return {
      // Sidebar
      isAddNewUserSidebarActive,
      isEditUserSidebarActive,
      optionsColumn,
      selectedColumns,
      filteredFields,
      isSlectColumnActive,
      fetchUsers,
      fetchGroups,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      roles,
      blankUserData,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

[dir="ltr"] .custom-checkbox.custom-control,
[dir="ltr"] .custom-radio.custom-control {
  padding-bottom: 10px;
}
</style>
